body {
  /* margin: 0; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* position: fixed; */
  /* height: 100vh; */  
}

#root {
  height: 100vh;  
  display: grid;
  grid-template-rows: auto 1fr auto;
  /* height: auto; */
  overflow: hidden;

}

main {
  /* flex: 1 1; */
  overflow: auto;
  /* Accounts for Footer height */
  /* margin-bottom: 50px; */
}

footer {
  display: grid;
  position: relative;
  bottom: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
